import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import _imports_0 from '@/assets/6189a29f.png';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
export default {
  __name: 'App',
  setup(__props) {
    // import { theme } from 'ant-design-vue';
    const route = useRoute();
    const currentRoute = computed(() => {
      const url = route.path;
      // 找到第一个 '/' 的位置
      const firstSlashIndex = url.indexOf('/');
      // 找到第二个 '/' 的位置
      const secondSlashIndex = url.indexOf('/', firstSlashIndex + 1);
      // 如果存在第二个 '/'，返回第二个 '/' 之前的部分
      if (secondSlashIndex !== -1) {
        return url.substring(0, secondSlashIndex);
      }
      // 如果不存在第二个 '/'，返回整个 URL
      return url;
    });
    const currentYear = computed(() => new Date().getFullYear());
    return (_ctx, _cache) => {
      const _component_router_link = _resolveComponent("router-link");
      const _component_a_menu_item = _resolveComponent("a-menu-item");
      const _component_a_menu = _resolveComponent("a-menu");
      const _component_a_layout_header = _resolveComponent("a-layout-header");
      const _component_router_view = _resolveComponent("router-view");
      const _component_a_layout_content = _resolveComponent("a-layout-content");
      const _component_a_layout_footer = _resolveComponent("a-layout-footer");
      const _component_a_layout = _resolveComponent("a-layout");
      const _component_a_config_provider = _resolveComponent("a-config-provider");
      return _openBlock(), _createBlock(_component_a_config_provider, {
        theme: {
          // algorithm: theme.defaultAlgorithm,
          token: {
            colorPrimary: '#5603ad'
          }
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_a_layout, {
          style: {
            background: '#fff'
          }
        }, {
          default: _withCtx(() => [_createVNode(_component_a_layout_header, {
            style: {
              background: '#fff'
            }
          }, {
            default: _withCtx(() => [_createVNode(_component_a_menu, {
              mode: "horizontal",
              "selected-keys": [currentRoute.value]
            }, {
              default: _withCtx(() => [_createVNode(_component_a_menu_item, {
                key: "/products"
              }, {
                default: _withCtx(() => [_createVNode(_component_router_link, {
                  to: "/products"
                }, {
                  default: _withCtx(() => _cache[0] || (_cache[0] = [_createTextVNode("贸易促销管理")])),
                  _: 1
                })]),
                _: 1
              }), _createVNode(_component_a_menu_item, {
                key: "/solutions"
              }, {
                default: _withCtx(() => [_createVNode(_component_router_link, {
                  to: "/solutions"
                }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [_createTextVNode("解决方案")])),
                  _: 1
                })]),
                _: 1
              }), _createVNode(_component_a_menu_item, {
                key: "/cases"
              }, {
                default: _withCtx(() => [_createVNode(_component_router_link, {
                  to: "/cases"
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [_createTextVNode("行业案例")])),
                  _: 1
                })]),
                _: 1
              }), _createVNode(_component_a_menu_item, {
                key: "/contact"
              }, {
                default: _withCtx(() => [_createVNode(_component_router_link, {
                  to: "/contact"
                }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [_createTextVNode("联系我们")])),
                  _: 1
                })]),
                _: 1
              })]),
              _: 1
            }, 8, ["selected-keys"])]),
            _: 1
          }), _createVNode(_component_a_layout_content, null, {
            default: _withCtx(() => [_createVNode(_component_router_view)]),
            _: 1
          }), _createVNode(_component_a_layout_footer, {
            style: {
              "text-align": "center"
            }
          }, {
            default: _withCtx(() => [_createElementVNode("p", null, "©2021-" + _toDisplayString(currentYear.value) + " cuxiaocloud.com 版权所有.", 1), _cache[4] || (_cache[4] = _createElementVNode("a", {
              "data-v-0958dba0": "",
              href: "https://beian.miit.gov.cn",
              target: "_blank",
              class: "ace-link ace-link-primary ali-report-link"
            }, [_createElementVNode("span", {
              "data-v-0958dba0": "",
              class: "ali-report-link-text ali-report-link-text-unique"
            }, " 浙ICP备2021038940号-1 ")], -1)), _cache[5] || (_cache[5] = _createElementVNode("img", {
              class: "logos",
              src: _imports_0,
              "data-v-2996d565": ""
            }, null, -1)), _cache[6] || (_cache[6] = _createElementVNode("a", {
              href: "https://beian.mps.gov.cn/#/query/webSearch?code=33021202002989",
              rel: "noreferrer",
              target: "_blank"
            }, "浙公网安备33021202002989号", -1))]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      });
    };
  }
};
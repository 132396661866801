import { createApp } from 'vue'
// import { createApp, defineAsyncComponent } from 'vue'
import App from './App.vue'
import router from './router'; // 确保导入了 router

// import Antd from 'ant-design-vue';
// import { SelectOption } from 'ant-design-vue/es/select';
// import Select from 'ant-design-vue/es/select';

// const MyComponent = () => import('./MyComponent.vue');

import { Button, Card, CardGrid, Checkbox, Col, Flex, Form, FormItem, Input, Layout, LayoutContent, LayoutFooter, LayoutHeader, List, ListItem, Menu, MenuItem, Result, Row, Select, SelectOption, Space, message } from 'ant-design-vue';

// 动态导入多个组件
// const components = {
//   'a-row': defineAsyncComponent(() => import('ant-design-vue/es/row')),
//   'a-col': defineAsyncComponent(() => import('ant-design-vue/es/col')),
//   'a-list': defineAsyncComponent(() => import('ant-design-vue/es/list')),
//   'a-list-item': defineAsyncComponent(() => import('ant-design-vue/es/list/Item')),
//   'a-space': defineAsyncComponent(() => import('ant-design-vue/es/space')),
//   'a-form': defineAsyncComponent(() => import('ant-design-vue/es/form')),
//   'a-form-item': defineAsyncComponent(() => import('ant-design-vue/es/form/FormItem')),
//   'a-input': defineAsyncComponent(() => import('ant-design-vue/es/input')),
//   'a-select': Select, // defineAsyncComponent(() => import('ant-design-vue/es/select')),
//   'a-select-option': SelectOption,
//   // 'a-select-option': defineAsyncComponent(() => import('ant-design-vue/es/select').then(module => module.Select.Option)),
// //   export const SelectOption = Select.Option;
// // export const SelectOptGroup = Select.OptGroup;
// // export default Select;
//   'a-checkbox': defineAsyncComponent(() => import('ant-design-vue/es/checkbox')),
//   'a-result': defineAsyncComponent(() => import('ant-design-vue/es/result')),
//   'a-flex': defineAsyncComponent(() => import('ant-design-vue/es/flex')),
//   'a-card': defineAsyncComponent(() => import('ant-design-vue/es/card')),
//   'a-card-grid': defineAsyncComponent(() => import('ant-design-vue/es/card').then(module => module.CardGrid)),
//   'a-button': defineAsyncComponent(() => import('ant-design-vue/es/button')),
//   'a-layout': defineAsyncComponent(() => import('ant-design-vue/es/layout')),
//   'a-layout-header': defineAsyncComponent(() => import('ant-design-vue/es/layout').then(module => module.LayoutHeader)),
//   'a-layout-content': defineAsyncComponent(() => import('ant-design-vue/es/layout').then(module => module.LayoutContent)),
//   'a-layout-footer': defineAsyncComponent(() => import('ant-design-vue/es/layout').then(module => module.LayoutFooter)),
//   'a-menu': defineAsyncComponent(() => import('ant-design-vue/es/menu')),
//   'a-menu-item': defineAsyncComponent(() => import('ant-design-vue/es/menu').then(module => module.MenuItem)),
// };

// 动态导入 Select.Option
// let SelectOption = null;
// import('ant-design-vue/es/select').then(module => {
//   SelectOption = module.SelectOption; // .Select.Option;
// });

// import 'ant-design-vue/dist/antd.css';
import 'ant-design-vue/dist/reset.css';
import '@/assets/css/index.css';

const app = createApp(App);
app.use(router); // 确保使用了 router

// // 统一注册为全局组件
// Object.entries(components).forEach(([name, component]) => {
//   app.component(name, component);
// });


/* 会自动注册 Button 下的子组件, 例如 Button.Group */
app.use(List)
app.use(ListItem)
app.use(Row)
app.use(Col)
app.use(Card)
app.use(Space)
app.use(Form)
app.use(FormItem)
app.use(Input)
app.use(Select)
app.use(SelectOption)
app.use(Checkbox)
app.use(Result)
app.use(Flex)
app.use(Card)
app.use(CardGrid)
app.use(Button)
app.use(Layout)
app.use(LayoutHeader)
app.use(LayoutContent)
app.use(LayoutFooter)
app.use(Menu)
app.use(MenuItem)


app.config.globalProperties.$message = message;

// app.use(Antd)
app.mount('#app')

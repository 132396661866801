import { createRouter, createWebHistory } from 'vue-router';
// import WeProducts from '../components/WeProducts.vue';
// import WeSolutions from '../components/WeSolutions.vue';
// import WeCases from '../components/WeCases.vue';
// import WeContact from '../components/WeContact.vue';
// import DemoRequest from '../components/DemoRequest.vue';
// import NotFound from '../components/NotFound.vue';

const WeProducts = () => import('@/components/WeProducts.vue');
const WeSolutions = () => import('@/components/WeSolutions.vue');
const WeCases = () => import('@/components/WeCases.vue');
const WeContact = () => import('@/components/WeContact.vue');
const DemoRequest = () => import('@/components/DemoRequest.vue');
const NotFound = () => import('@/components/NotFound.vue');

const routes = [
  { path: '/', redirect: '/products', },
  { path: '/products', component: WeProducts },
  { path: '/solutions', component: WeSolutions },
  { path: '/cases', component: WeCases },
  { path: '/contact', component: WeContact },
  { path: '/products/demo', component: DemoRequest },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
